body {
  background-color: #fefefe;
  padding: 0;
}

@media screen and (max-width: 760px) {
  .App {
    margin: 0 auto !important;
    box-shadow: none !important;
  }

  body {
    background-color: #2e2e2e;
  }
}

.App {
  font-size: 15px;
  margin: 20px auto;
  width: auto;
  max-width: 700px;
  padding: 10px 30px;
  padding-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 1px #bbb;
  border-radius: 5px;
  color: #fefefe;
  background-color: #2e2e2e;
  z-index: 2;
  backdrop-filter: blur(0.6rem);
  //background: rgba(46, 46, 46, 0.9);
  transition: all linear 0.5s;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &.pos1 {
    top: 25px;
    left: -25px;
    right: 25px;
    z-index: -1;
  }

  .me {
    color: goldenrod;
  }

  header {
    color: palegoldenrod;
    font-size: 17px;
    display: flex;
    flex-flow: row;
    padding: 10px 0;
    padding-top: 15px;

    h3 {
      flex: 1;
      margin: 0;
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .modes {
      flex: 0;
    }
  }

  .code {
    display: flex;
    flex-flow: row;

    code:last-child {
      flex: 1;
    }

    code:first-child {
      color: #888;
    }

    a {
      color: lightblue;
    }
  }

  .ccc {
    color: #222;
    background-color: #ccc;
    cursor: pointer;
    padding: 0 5px;
  }

  .learning {
    color: #ccc;
    cursor: pointer;
  }

  hr {
    margin: 25px 0;
    border-color: #555;
    border-width: 0.5px;
  }

  .cursor {
    animation: blink 0.8s infinite;
    font-weight: bold;
  }

  .modes {
    text-align: right;

    .modeButtons {
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-left: 5px;
      cursor: pointer;
      background: rgba(64, 129, 122, 0.7);
      border: solid 1px lightblue;

      &:hover {
        background: rgba(64, 129, 122, 1);
        border: solid 1px #ffffff;
      }
    }
  }

  &.glass {
    background: linear-gradient(
      to bottom right,
      rgba(64, 129, 122, 0.7),
      rgba(124, 189, 201, 0.7)
    );
    backdrop-filter: blur(0.7rem);
    box-shadow: none; //0 4px 5px 1px #A5F1F6;
    color: #fefefe;

    .modes {
      .modeButtons {
        background: #555;

        &:hover {
          background: #222;
        }
      }
    }

    hr {
      border-color: #d0faf9;
      color: #d0faf9;
    }

    .code {
      code:first-child {
        color: #003b32;
      }
    }

    a {
      color: lightblue;
    }

    .learning {
      color: lightblue;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.heart {
  animation: blink2 0.5s infinite;
  font-size: 17px;
}

@keyframes blink2 {
  0% {
    color: red;
  }

  50% {
    color: yellow;
  }

  100% {
    color: red;
  }
}
